<script setup>
import { ref } from 'vue';
import { BLink, BTable } from 'bootstrap-vue-next';
import { buildUrl } from '~/utils/UrlUtils';
import FilterCard from '~/common/components/FilterCard.vue';

const leaders = ref([]);
const busy = ref(true);
const filterData = ref({
  fromDate: '',
  toDate: '',
  communityOnly: true,
});

const fields = [
  {
    key: 'username',
    label: 'Username',
  },
  {
    key: 'merged_merge_requests',
    label: 'Merged MRs',
  },
  {
    key: 'merged_commits',
    label: 'Commits',
  },
  {
    key: 'opened_merge_requests',
    label: 'Opened MRs',
  },
  {
    key: 'opened_issues',
    label: 'Issues',
  },
  {
    key: 'added_notes',
    label: 'Notes',
  },
  {
    key: 'score',
    label: 'Score',
  },
];

const filter = () => {
  busy.value = true;
  fetchData();
};

const fetchData = async () => {
  const query = {
    from_date: filterData.value.fromDate,
    to_date: filterData.value.toDate,
    community_only: filterData.value.communityOnly,
  };
  const url = buildUrl('/api/v1/leaderboard', { query });

  const response = await fetch(url);
  leaders.value = await response.json();
  busy.value = false;
};
</script>

<template>
  <FilterCard
    v-model="filterData"
    show-community-only
    @filter="filter"
  />

  <BTable
    :fields="fields"
    :items="leaders"
    :busy="busy"
    show-empty
  >
    <template #cell(username)="data">
      <BLink
        :to="{
          name: 'user',
          params: { userId: data.item.id },
          query: { fromDate: filterData.fromDate, toDate: filterData.toDate }
        }"
      >
        {{ data.value }}
      </BLink>
    </template>
  </BTable>
</template>
