<script setup>
import { BContainer } from 'bootstrap-vue-next';
import NavigationMenu from '~/common/components/NavigationMenu.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const showNav = computed(() => {
  return route?.name !== 'manage-merge-request';
});
</script>

<template>
  <NavigationMenu v-if="showNav" />
  <BContainer class="pt-2">
    <router-view />
  </BContainer>
</template>
