<script setup>
import { inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import { BButton, BLink, BTable } from 'bootstrap-vue-next';
import { buildUrl } from '~/utils/UrlUtils';
import FilterCard from '~/common/components/FilterCard.vue';

const admin = inject('userAdmin');

const data = ref({});
const busy = ref(true);
const filterData = ref({
  fromDate: '',
  toDate: '',
});

const route = useRoute();

const mergedMergeRequestFields = [
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'opened_date',
    label: 'Open date',
  },
  {
    key: 'merged_date',
    label: 'Merge date',
  },
];

const openedMergeRequestFields = [
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'opened_date',
    label: 'Open date',
  },
  {
    key: 'state_id',
    label: 'State',
  },
];

const commitFields = [
  {
    key: 'merge_request_title',
    label: 'Merge request title',
  },
  {
    key: 'sha',
    label: 'SHA',
  },
  {
    key: 'committed_date',
    label: 'Commit date',
  },
  {
    key: 'merged_date',
    label: 'Merge date',
  },
];

const issueFields = [
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'opened_date',
    label: 'Open date',
  },
  {
    key: 'state_id',
    label: 'State',
  },
];

const noteFields = [
  {
    key: 'noteable_title',
    label: 'Noteable title',
  },
  {
    key: 'added_date',
    label: 'Added date',
  },
];

const filter = () => {
  busy.value = true;
  fetchData();
};

const fetchData = async () => {
  const { userId } = route.params;
  const query = { from_date: filterData.value.fromDate, to_date: filterData.value.toDate };
  const url = buildUrl(`/api/v1/users/${userId}`, { query });

  const response = await fetch(url);
  data.value = await response.json();
  busy.value = false;
};
</script>

<template>
  <FilterCard
    v-model="filterData"
    @filter="filter"
  />

  <h1 class="d-flex align-items-center justify-content-between">
    <a :href="`https://gitlab.com/${data.username}`">{{ data.username }}</a>
    <BButton
      v-if="admin"
      variant="outline-primary"
      size="sm"
      :to="{
        name: 'user-edit',
        params: { userId: data.id },
      }"
    >
      Edit user
    </BButton>
  </h1>

  <h2>Merged Merge Requests ({{ data.merged_merge_requests?.length }})</h2>
  <BTable
    :fields="mergedMergeRequestFields"
    :items="data.merged_merge_requests"
    :busy="busy"
    show-empty
  >
    <template #cell(title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
  </BTable>

  <h2>Opened Merge Requests ({{ data.opened_merge_requests?.length }})</h2>
  <BTable
    :fields="openedMergeRequestFields"
    :items="data.opened_merge_requests"
    :busy="busy"
    show-empty
  >
    <template #cell(title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
  </BTable>

  <h2>Commits ({{ data.merged_commits?.length }})</h2>
  <BTable
    :fields="commitFields"
    :items="data.merged_commits"
    :busy="busy"
    show-empty
  >
    <template #cell(merge_request_title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.merge_request_web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
  </BTable>

  <h2>Opened Issues ({{ data.opened_issues?.length }})</h2>
  <BTable
    :fields="issueFields"
    :items="data.opened_issues"
    :busy="busy"
    show-empty
  >
    <template #cell(title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
  </BTable>

  <h2>Added Notes ({{ data.added_notes?.length }})</h2>
  <BTable
    :fields="noteFields"
    :items="data.added_notes"
    :busy="busy"
    show-empty
  >
    <template #cell(noteable_title)="cell">
      <BLink
        is-unsafe-link
        :href="cell.item.web_url"
        target="_blank"
      >
        {{ cell.value }}
      </BLink>
    </template>
  </BTable>
</template>
