<script setup>
import { inject } from 'vue';
import {
  BCollapse,
  BDropdownItem,
  BNavbar,
  BNavbarBrand,
  BNavItem,
  BNavItemDropdown,
  BNavbarNav,
  BNavbarToggle,
} from 'bootstrap-vue-next';

const username = inject('username');
</script>

<template>
  <BNavbar>
    <BNavbarBrand
      tag="h1"
      class="mb-0"
      :to="{ name: 'home' }"
    >
      Contributors
    </BNavbarBrand>
    <BNavbarToggle target="main-nav-collapse" />
    <BCollapse
      id="main-nav-collapse"
      is-nav
    >
      <BNavbarNav>
        <BNavItem :to="{ name: 'leaderboard' }">
          Leaderboard
        </BNavItem>
      </BNavbarNav>
      <BNavbarNav class="ms-auto mb-2 mb-lg-0">
        <BNavItemDropdown
          v-if="username"
          :text="username"
        >
          <BDropdownItem :href="`https://gitlab.com/${username}`">
            Profile
          </BDropdownItem>
          <BDropdownItem href="/logout">
            Logout
          </BDropdownItem>
        </BNavItemDropdown>
        <BNavItem
          v-else
          href="/login"
        >
          Login
        </BNavItem>
      </BNavbarNav>
    </BCollapse>
  </BNavbar>
</template>
