import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

// bootstrap-vue-next
import { createBootstrap } from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

// App
import RootApp from '~/App.vue';
import routes from '~/routes';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';

const httpLink = createHttpLink({
  uri: 'https://gitlab.com/api/graphql',
});

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});
const bootstrap = createBootstrap({ directives: true });

const el = document.getElementById('app');
const username = el.getAttribute('data-username');
const userAdmin = el.getAttribute('data-user-admin');

createApp(RootApp)
  .provide(DefaultApolloClient, apolloClient)
  .provide('username', username)
  .provide('userAdmin', userAdmin === 'true')
  .use(router, bootstrap)
  .mount('#app');
